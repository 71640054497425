import { button, classesToClassList } from "@components/solid/classLists";
import { nrkMediaDirekteNotlive } from "@nrk/core-icons";
import { Show, type Component, type JSX } from "solid-js";
import styles from "./DirectButton.module.css";

export const DirectButton: Component<{
  isLiveTime?: boolean;
  class?: string;
  onClick: JSX.EventHandler<HTMLButtonElement, Event>;
}> = (props) => {
  return (
    <button
      classList={{
        ...button({ type: "ghost", variant: "text-icon" }),
        ...classesToClassList(props.class),
      }}
      onClick={props.onClick}
    >
      <span
        classList={{
          ...classesToClassList("nrk-typography-caption--2", styles.buttonText),
          [styles.live]: props.isLiveTime,
        }}
      >
        Direkte
      </span>
      <Show
        when={props.isLiveTime}
        fallback={<span innerHTML={nrkMediaDirekteNotlive} />}
      >
        <span class={styles.liveIcon}>
          <span class={styles.livePulse} />
        </span>
      </Show>
    </button>
  );
};
