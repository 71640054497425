import audioPlayer from "@components/solid/Player/AudioPlayer";
import { Show, type ParentComponent } from "solid-js";
import { MiniPlayer } from "./MiniPlayer";

export const Player: ParentComponent = () => {
  const { hasPlaybackSession } = audioPlayer;

  // onMount(async () => {
  // await loadAudioPlayer({ channelId: "p2", mediaType: MEDIA_TYPES.CHANNEL });
  //   await loadAudioPlayer({
  //     episodeId: "l_d9687708-ae90-41f9-a877-08ae9011f952",
  //     seriesId: "baade_erlend_og_steinar_",
  //     mediaType: MEDIA_TYPES.PODCAST,
  //   });
  // });

  return (
    <Show when={hasPlaybackSession()}>
      <MiniPlayer />
    </Show>
  );
};
