import { classesToClassList } from "@components/solid/classLists";
import { ChannelLogo } from "@components/solid/Player/ChannelLogo";
import { MEDIA_TYPES, type MediaIds } from "@components/solid/Player/lib/toSrc";
import { Match, Show, Switch, type Component } from "solid-js";
import styles from "./CurrentlyPlaying.module.css";

interface CurrentlyPlayingProps {
  mainTitle?: string;
  secondaryTitle?: string;
  activeIndexpointTitle?: string;
  imageSrc?: string;
  mediaIds?: MediaIds;
}

export const CurrentlyPlaying: Component<CurrentlyPlayingProps> = (props) => {
  return (
    <div class={styles.currentlyPlaying}>
      <Switch>
        <Match
          when={
            props.mediaIds?.mediaType === MEDIA_TYPES.CHANNEL &&
            !!props.mediaIds?.channelId
          }
        >
          <ChannelLogo channelId={props.mediaIds!.channelId!} />
        </Match>
        <Match
          when={
            props.mediaIds?.mediaType !== MEDIA_TYPES.CHANNEL && props.imageSrc
          }
        >
          <img alt="" class={styles.image} src={props.imageSrc} />
        </Match>
      </Switch>
      <div class={styles.titlesWrapper}>
        <Show when={props.mainTitle}>
          <span classList={classesToClassList("nrk-typography-subhead--bold")}>
            {props.mainTitle}
          </span>
        </Show>
        <Show when={props.secondaryTitle}>
          <span
            classList={classesToClassList(
              "nrk-typography-footnote",
              styles.secondaryTitle,
            )}
          >
            {props.secondaryTitle}
          </span>
        </Show>
      </div>
    </div>
  );
};
