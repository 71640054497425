import { button, classesToClassList } from "@components/solid/classLists";
import {
  nrkMediaVolume1,
  nrkMediaVolume2,
  nrkMediaVolume3,
  nrkMediaVolumeMuted,
} from "@nrk/core-icons";
import {
  type Component,
  type JSX,
  Match,
  onCleanup,
  onMount,
  Switch,
} from "solid-js";
import styles from "./VolumeButton.module.css";

export const VolumeButton: Component<{
  onClick: JSX.EventHandler<HTMLButtonElement, Event>;
  volume: number;
  isMuted: boolean;
  setVolume: (volume: number) => void;
  unMute: () => void;
  class?: string;
}> = (props) => {
  let volumeButtonWrapperRef: HTMLDivElement | undefined;

  onMount(() => {
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.deltaY < 0) {
        props.setVolume(Math.max(props.volume - 0.025, 0));
      } else {
        props.setVolume(Math.min(props.volume + 0.025, 1));
      }
    };

    volumeButtonWrapperRef?.addEventListener("wheel", handleWheel);

    onCleanup(() => {
      volumeButtonWrapperRef?.removeEventListener("wheel", handleWheel);
    });
  });

  return (
    <div
      ref={volumeButtonWrapperRef}
      classList={classesToClassList(styles.volumeButtonWrapper, props.class)}
    >
      <button
        class={styles.volumeButton}
        classList={button({ type: "ghost", variant: "icon" })}
        onClick={props.onClick}
      >
        <Switch>
          <Match when={props.volume === 0 || props.isMuted}>
            <span innerHTML={nrkMediaVolumeMuted} />
          </Match>
          <Match when={props.volume >= 0.66}>
            <span innerHTML={nrkMediaVolume3} />
          </Match>
          <Match when={props.volume >= 0.33}>
            <span innerHTML={nrkMediaVolume2} />
          </Match>
          <Match when={props.volume > 0}>
            <span innerHTML={nrkMediaVolume1} />
          </Match>
        </Switch>
      </button>
      <div class={styles.inputRangeWrapper}>
        <input
          class={styles.inputRange}
          type="range"
          min="0"
          max="100"
          value={props.isMuted ? 0 : props.volume * 100}
          onInput={(event) => {
            const nextVolume = Number(event.currentTarget.value) / 100;
            props.setVolume(nextVolume);
            if (nextVolume > 0) {
              props.unMute();
            }
          }}
        />
      </div>
    </div>
  );
};
