import AudioPlayer from "@components/solid/Player/AudioPlayer";
import { Dialog } from "@components/solid/Player/controls/PlaybackSpeed/Dialog";
import { DialogContent } from "@components/solid/Player/controls/PlaybackSpeed/DialogContent";
import { DialogHeading } from "@components/solid/Player/controls/PlaybackSpeed/DialogHeading";
import { ScrollButton } from "@components/solid/Player/controls/PlaybackSpeed/ScrollButton";
import { ToggleButton } from "@components/solid/Player/controls/PlaybackSpeed/ToggleButton";
import { createScrollPosition } from "@solid-primitives/scroll";
import {
  createEffect,
  createSignal,
  onCleanup,
  type Component,
} from "solid-js";
import style from "./PlaybackSpeed.module.css";

let wrapperRef: HTMLDivElement;
let dialogRef: HTMLDialogElement;
let dialogInnerRef: HTMLDivElement;
let dialogContentRef: HTMLUListElement;

const initialDialogPosition = {
  left: "auto",
  top: "auto",
  right: "auto",
  bottom: "auto",
};
const [dialogPosition, setDialogPosition] = createSignal<{
  top: string;
  right?: string;
  bottom?: string;
  left: string;
}>(initialDialogPosition);

const [buttonsDisabledState, setButtonsDisabledState] = createSignal({
  up: false,
  down: false,
});

export const PlaybackSpeed: Component<{ rate: number }> = (props) => {
  const { setPlaybackRate } = AudioPlayer;
  const scrollPosition = createScrollPosition(() => dialogContentRef);

  createEffect(() => {
    updateDialogPosition();
    window.addEventListener("resize", updateDialogPosition);

    onCleanup(() => {
      window.removeEventListener("resize", updateDialogPosition);
    });
  });

  createEffect(() => {
    setButtonsDisabledState({
      up: scrollPosition.y <= 0,
      down:
        scrollPosition.y >=
        dialogContentRef.scrollHeight - dialogContentRef.offsetHeight,
    });
  });

  createEffect(() => {
    const handleMouseEnter = (): void => {
      dialogRef.classList.add(style.mouseIsOver);
    };
    const handleMouseLeave = (): void => {
      dialogRef.classList.remove(style.mouseIsOver);
    };

    dialogInnerRef.addEventListener("mouseenter", handleMouseEnter);
    dialogInnerRef.addEventListener("mouseleave", handleMouseLeave);

    onCleanup(() => {
      dialogInnerRef.removeEventListener("mouseenter", handleMouseEnter);
      dialogInnerRef.removeEventListener("mouseleave", handleMouseLeave);
    });
  });
  return (
    <div ref={wrapperRef}>
      <ToggleButton
        onClick={() => {
          dialogRef.showModal();
          dialogContentRef
            .querySelector("[data-active=true]")
            ?.scrollIntoView({ block: "center" });
        }}
        rate={props.rate}
      />
      <Dialog
        ref={dialogRef}
        top={dialogPosition().top}
        left={dialogPosition().left}
      >
        <div ref={dialogInnerRef} class={style.DialogInner}>
          <DialogHeading />
          <ScrollButton
            disabled={buttonsDisabledState().up}
            onClick={() => {
              dialogContentRef.scrollBy({
                top: -dialogContentRef.offsetHeight + 36,
                behavior: "smooth",
              });
            }}
            type="up"
          />
          <DialogContent
            ref={dialogContentRef}
            currentRate={props.rate}
            onSelect={(rate) => {
              setPlaybackRate(rate);
              dialogRef.close();
            }}
          />
          <ScrollButton
            disabled={buttonsDisabledState().down}
            onClick={() => {
              dialogContentRef.scrollBy({
                top: dialogContentRef.offsetHeight - 36,
                behavior: "smooth",
              });
            }}
            type="down"
          />
        </div>
      </Dialog>
    </div>
  );
};

function isMinMDBreakpoint() {
  return window.matchMedia("(min-device-width: 768px)").matches;
}

function updateDialogPosition() {
  if (!wrapperRef) return;

  if (isMinMDBreakpoint()) {
    const buttonRect = wrapperRef.getBoundingClientRect();

    // getBoundingClientRect() doesn't return the correct width and height for the dialog element while display is none
    const dialogWidth = parseFloat(getComputedStyle(dialogRef).width);
    const dialogHeight = parseFloat(getComputedStyle(dialogRef).height);

    setDialogPosition({
      top: buttonRect.bottom - dialogHeight + "px",
      left: buttonRect.left - dialogWidth / 2 + buttonRect.width / 2 + "px",
    });
  } else {
    setDialogPosition(initialDialogPosition);
  }
}
