import { classesToClassList } from "@components/solid/classLists";
import { type Component } from "solid-js";
import style from "./DialogHeading.module.css";

export const DialogHeading: Component<{}> = () => {
  return (
    <h2
      classList={classesToClassList(
        "nrk-typography-caption--1",
        style.DialogHeading,
      )}
      class="nrk-typography-caption--1"
    >
      Avspillingshastighet
    </h2>
  );
};
