import { button } from "@components/solid/classLists";
import { nrkChevronDown, nrkChevronUp } from "@nrk/core-icons";
import { Match, Switch, type Component } from "solid-js";
import style from "./PlaybackSpeed.module.css";

export const ScrollButton: Component<{
  onClick: () => void;
  type: "up" | "down";
  disabled: boolean;
}> = (props) => {
  return (
    <button
      disabled={props.disabled}
      classList={{
        ...button({ variant: "icon" }),
        [style.ScrollButton]: true,
        [style.ScrollButtonUp]: props.type === "up",
        [style.ScrollButtonDown]: props.type === "down",
      }}
      onClick={props.onClick}
    >
      <Switch>
        <Match when={props.type === "up"}>
          <span innerHTML={nrkChevronUp} />
        </Match>
        <Match when={props.type === "down"}>
          <span innerHTML={nrkChevronDown} />
        </Match>
      </Switch>
    </button>
  );
};
