import { button } from "@components/solid/classLists";
import { type Component, type JSX } from "solid-js";

export const ToggleButton: Component<{
  rate: number;
  onClick: JSX.EventHandler<HTMLButtonElement, Event>;
}> = (props) => {
  return (
    <button
      onClick={props.onClick}
      aria-label={`Åpne meny for avspillingshastighet. Nåværende hastighet er ${props.rate}x`}
      classList={button({ type: "ghost" })}
    >
      {props.rate}x
    </button>
  );
};
