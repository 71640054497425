import { type ParentComponent } from "solid-js";
import style from "./PlaybackSpeed.module.css";

export const Dialog: ParentComponent<{
  ref: HTMLDialogElement;
  top: string;
  left: string;
}> = (props) => {
  return (
    <dialog
      onClick={(event) => {
        const dialogElement = event.target as HTMLDialogElement;
        if (dialogElement && dialogElement.tagName === "DIALOG") {
          dialogElement.close();
        }
      }}
      ref={props.ref}
      open={false}
      id="PlaybackSpeedDialog"
      class={style.PlaybackSpeedDialog}
      style={{ top: props.top, left: props.left }}
    >
      {props.children}
    </dialog>
  );
};
