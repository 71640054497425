import { button } from "@components/solid/classLists";
import { nrkMediaRwd15sec } from "@nrk/core-icons";
import { type JSX, type Component } from "solid-js";

export const RwdButton: Component<{
  onClick: JSX.EventHandler<HTMLButtonElement, Event>;
}> = (props) => {
  return (
    <button onClick={props.onClick} classList={button({ type: "ghost" })}>
      <span innerHTML={nrkMediaRwd15sec} />
    </button>
  );
};
