import { button } from "@components/solid/classLists";
import { nrkMediaFfw15sec } from "@nrk/core-icons";
import { type Component, type JSX } from "solid-js";

export const FfwButton: Component<{
  onClick: JSX.EventHandler<HTMLButtonElement, Event>;
  class?: string;
}> = (props) => {
  return (
    <button
      onClick={props.onClick}
      classList={button({ type: "ghost" })}
      class={props.class}
    >
      <span innerHTML={nrkMediaFfw15sec} />
    </button>
  );
};
