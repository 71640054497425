import { classesToClassList } from "@components/solid/classLists";
import {
  nrkLogoNrkFolkemusikkOnDark,
  nrkLogoNrkJazzOnDark,
  nrkLogoNrkKlassiskOnDark,
  nrkLogoNrkMp3OnDark,
  nrkLogoNrkP13OnDark,
  nrkLogoNrkP1OnDark,
  nrkLogoNrkP1PlussOnDark,
  nrkLogoNrkP2OnDark,
  nrkLogoNrkP3OnDark,
  nrkLogoNrkP3UrortOnDark,
  nrkLogoNrkP3XOnDark,
  nrkLogoNrkSapmiOnDark,
  nrkLogoNrkSuperOnDark,
} from "@nrk/core-icons/logo";
import {
  nrkLogoPreviewNrkNyheterOnDark,
  nrkLogoPreviewNrkSportOnDark,
} from "@nrk/core-icons/preview";
import { createEffect, createSignal, Show, type Component } from "solid-js";
import styles from "./ChannelLogo.module.css";

const channelIdLogoMap: Record<string, string> = {
  p1: nrkLogoNrkP1OnDark,
  p2: nrkLogoNrkP2OnDark,
  p3: nrkLogoNrkP3OnDark,
  p3x: nrkLogoNrkP3XOnDark,
  mp3: nrkLogoNrkMp3OnDark,
  p13: nrkLogoNrkP13OnDark,
  p1pluss: nrkLogoNrkP1PlussOnDark,
  alltid_nyheter: nrkLogoPreviewNrkNyheterOnDark,
  sport: nrkLogoPreviewNrkSportOnDark,
  radio_super: nrkLogoNrkSuperOnDark,
  klassisk: nrkLogoNrkKlassiskOnDark,
  sapmi: nrkLogoNrkSapmiOnDark,
  jazz: nrkLogoNrkJazzOnDark,
  folkemusikk: nrkLogoNrkFolkemusikkOnDark,
  urort: nrkLogoNrkP3UrortOnDark,
};

interface ChannelLogoProps {
  channelId: string;
  class?: string;
}

export const ChannelLogo: Component<ChannelLogoProps> = (props) => {
  const [parentChannelId, setParentChannelId] = createSignal<string | "">("");

  createEffect(() => {
    const nextParentChannelId = props.channelId.startsWith("p1_")
      ? "p1"
      : props.channelId;

    setParentChannelId(nextParentChannelId);
  });

  return (
    <Show
      when={parentChannelId().length > 0 && channelIdLogoMap[parentChannelId()]}
    >
      <span
        aria-hidden="true"
        innerHTML={channelIdLogoMap[parentChannelId()]}
        classList={classesToClassList(styles.channelLogo, props.class)}
      ></span>
    </Show>
  );
};
