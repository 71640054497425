import { button } from "@components/solid/classLists";
import { nrkFullscreen } from "@nrk/core-icons";
import { type Component } from "solid-js";

export const OpenFullscreenPlayerButton: Component<{}> = () => {
  return (
    <button
      onClick={() => {
        console.log("TODO");
      }}
      classList={button({ variant: "icon", type: "ghost" })}
    >
      <span innerHTML={nrkFullscreen} />
    </button>
  );
};
